html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.weather-station, main{
  height: 100%;
  /*display: flex;*/
  /*flex-direction: column;*/
}
@media (max-width: 960px) {
  .weather-station-root, main{
    height: calc(100% - 56px) !important;
  }
}
